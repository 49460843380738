import {
  Link
} from "react-router-dom";

import AnchorLink from 'react-anchor-link-smooth-scroll'
import Logo from '../../assets/images/lunar_ventures_logo_color_black_50px.png'
// import LogoMobile from '../../assets/images/lunar_ventures_logo_color_black_icon.png'

export default function Navigation({ navigation }) {
  return (
    <header className="bg-white">
      <nav className="max-w-7xl mx-auto px-6 sm:px-4 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-pink-500 lg:border-none">
          <div className="flex items-center">
            <Link to='/'>
              <span className="sr-only">Lunar Ventures</span>
              <img
                className="h-10 w-auto"
                src={Logo}
                alt=""
              />
            </Link>
            <div className="ml-10 space-x-8 sm:space-x-4 lg:block">
              {navigation.map((link) => (
                <>
                  {link.anchor ? <AnchorLink offset='100' key={link.name} href={link.href} className="text-base font-medium text-black hover:text-pink-500">
                    {link.name}

                  </AnchorLink> : <Link key={link.name} to={link.href} className="text-base font-medium text-black hover:text-pink-500">
                    {link.name}

                  </Link>}
                </>
              ))}
            </div>
          </div>

        </div>
        {/* <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigation.map((link) => (
            <a key={link.name} href={link.href} className="text-base font-medium text-black hover:text-pink-50">
              {link.name}
            </a>
          ))}
        </div> */}
      </nav>
    </header>
  )
}