import React from 'react';
import Content1 from '../components/landingpage/Content1';
import Feed from '../components/landingpage/Feed';
import { Hero } from '../components/landingpage/Hero';
import Team from '../components/landingpage/Team';
import PortfolioLogos from '../components/landingpage/PortfolioLogos';
// import InvestmentsCarousell from '../components/landingpage/InvestmentsCarousell';

function Home(props) {
    return (
        <div>
        <main style={{ marginTop: -90 }} className="relative top-0">
          <section id='hero'>
            <Hero></Hero>
          </section>

          <section id='mission'>
            <Content1></Content1>
          </section>
          <section id='team'>
            <Team></Team>
          </section>
          <section id='portfolio'>
            <PortfolioLogos></PortfolioLogos>
            {/* <InvestmentsCarousell></InvestmentsCarousell> */}
          </section>



          <section id='insights'>
            <Feed></Feed>
          </section>
        </main>
      </div>
    );
}

export default Home;