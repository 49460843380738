/* This example requires Tailwind CSS v2.0+ */

export default function Content1() {
  return (
    <div className="bg-pickled-bluewood-500 text-white py-12">
      <div className="mx-auto max-w-md px-4 text-left sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="max-w-3xl">
        <p className="text-7xl  font-light sm:text-4xl">
          We care about your code, not your sales pipeline.
        </p>
        <p className="mt-5 font-light tracking-wider mx-auto text-xl">
        We like to work with founders as early as we can:
        </p>
          <ul className="p-6 tracking-wider font-light list-disc leading-loose text-xl">
            <li>
              €300K - €1M initial investment
            </li>
            <li>
              into technical teams with strong R&amp;D backgrounds
            </li>
            <li>
              building with ♥ in Europe products that will sell globally
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}