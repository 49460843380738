import { useState, useEffect } from 'react'
import Prismic from '@prismicio/client'
import dotenv from 'dotenv'
dotenv.config()

const apiEndpoint = 'https://lunarventures.prismic.io/api/v2'
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const Client = Prismic.client(apiEndpoint, { accessToken })



function Newsletter(props) {
    const [newsletter, setNewsletterData] = useState([])

    useEffect(() => {
      const fetchData = async () => {
        const response = await Client.query(
          Prismic.Predicates.at('document.type', 'newsletter'),
          { orderings : '[my.newsletter.release-date] ' }
        )
        if (response) {
          setNewsletterData(response.results)
          console.log(response.results)
        }
      }
      fetchData()
    }, [])
    return (
        <div className="bg-pickled-bluewood-50 text-left">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
          <div>
            <h2 className="text-3xl tracking-tight font-normal text-pickled-bluewood-500 sm:text-4xl pb-4">Newsletter</h2>
            <form id="newsletter" className="my-4 sm:flex sm:max-w-md lg:mt-0" action="//berlininnovation.us15.list-manage1.com/subscribe/post?u=6a71d9290ead656c2c0790fc4&amp;id=3e01a9c859" method="newsletter"  noValidate={true}>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="EMAIL"
              id="newsletter-email"
              autoComplete="email"
              required
              className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400 sm:max-w-xs"
              placeholder="Enter your email"
            />
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                className="w-full bg-pink-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
              >
                Subscribe
              </button>
            </div>
          </form>
            <p className="mt-2 text-xl font-light tracking-wide text-white sm:mt-4">
              Past Editions
            </p>
          </div>
          <div className="mt-12 grid gap-y-2 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
            {newsletter.map((newsletter) => (
              <div className="bg-gray-50 p-12" key={newsletter.data.title[0].text}>
                <div>
                  <a href={newsletter.data.link.url} className="inline-block">
                    <span
                      className='bg-pink-300 text-white inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium'
                    >
                      {newsletter.data.title[0].text}
                    </span>
                  </a>
                </div>
                {/* <a target="_blank" rel="noreferrer" href={newsletter.data.link.url} className="block mt-4">
                  <p className="text-xl font-semibold tracking-wide text-pickled-bluewood-500">{newsletter.data.subtitle[0].text}</p>
                  <p className="mt-3 text-gray-900 ">{newsletter.data.short_description[0].text}</p>
                </a> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}

export default Newsletter;