import { useState, useEffect } from 'react'
import Prismic from '@prismicio/client'
import dotenv from 'dotenv'
dotenv.config()

const apiEndpoint = 'https://lunarventures.prismic.io/api/v2'
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const Client = Prismic.client(apiEndpoint, { accessToken })

export default function Feed() {
  const [posts, setPostsData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'post'),
        { orderings : '[my.post.position], [my.post.release-date] ' }
      )
      if (response) {
        setPostsData(response.results)
        console.log(response.results)
      }
    }
    fetchData()
  }, [])

  return (
    <div className="bg-pickled-bluewood-50 text-left">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
        <div>
          <h2 className="text-3xl tracking-tight font-normal text-pickled-bluewood-500 sm:text-4xl">Insights</h2>
          <p className="mt-3 text-xl font-light tracking-wide text-white sm:mt-4">
            Read, watch and listen to content we share accross the web.
          </p>
        </div>
        <div className="mt-12 grid gap-y-2 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {posts.map((post) => (
            <div className="bg-gray-50 p-12" key={post.data.title[0].text}>
              <div>
                <a href={post.data.link.url} className="inline-block">
                  <span
                    className='bg-pink-300 text-white inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium'
                  >
                    {post.data.type}
                  </span>
                </a>
              </div>
              <a target="_blank" rel="noreferrer" href={post.data.link.url} className="block mt-4">
                <p className="text-xl font-semibold tracking-wide text-pickled-bluewood-500">{post.data.title[0].text}</p>
                <p className="mt-3 text-gray-900 ">{post.data.short_description[0].text}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
