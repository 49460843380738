import Logo from '../../assets/images/lunar_ventures_logo_color_white_200px.png'
import Background from '../../assets/images/hero-bg.png'


export const Hero = () => {
    return (

    <div className="min-h-full">
      <div className="relative overflow-hidden">
        <main>
          <div style={{backgroundImage: `url(${Background})`}} className="pt-10 pb-10 bg-cover bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
            <div className="mx-auto max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                  <div className="lg:py-24 sm:py-24">
                    <h1 className="mt-6 mb-10 text-6xl  font-light text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                      <img alt="" className="w-60 h-25 my-16 lg:m-0 lg:mb-25 sm:m-auto sm:mb-25" src={Logo} />
                      <span className="mt-10 block">Backing European</span>
                      <span className="mt-3 block">Deep Tech Founders</span>
                    </h1>
                    <p className="mt-3 mb-50 text-base text-gray-200 sm:mt-5 sm:text-2xl lg:text-2lg xl:text-2xl font-light tracking-wide">
                    If you’re a technical founder who's struggled to explain your tech and vision to investors, we set up a fund for you. We invest pre-revenue to help turn your science fiction into reality. 
                    </p>
                  </div>
                </div>
                <div className="mt-12 -mb-10 sm:-mb-28 lg:m-0 lg:relative">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                    {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                    {/* <img
                      className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src={Illustration}
                      alt=""
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
};