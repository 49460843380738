import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import Iframe from 'react-iframe'

import './assets/styles/App.css';
import Footer from './components/Footer';
import Navigation from "./components/landingpage/Navigation";
import Home from './pages/Home';
import Imprint from "./pages/Imprint";
import Newsletter from "./pages/Newsletter";


const default_navigation = [
  { name: 'Mission', href: '#mission', anchor: true },
  { name: 'Team', href: '#team', anchor: true },
  { name: 'Portfolio', href: '#portfolio', anchor: true },
  { name: 'Insights', href: '#insights', anchor: true },
]

const alt_navigation = [
  { name: 'Mission', href: '/#mission', anchor: false },
  { name: 'Team', href: '/#team', anchor: false },
  { name: 'Portfolio', href: '/#portfolio', anchor: false },
  { name: 'Insights', href: '/#insights', anchor: false },
]

function App() {
  return (
    <Router>
      <div className="App">
        <div>
          <Switch>

            <Route path="/newsletter/:id" children={<NewsletterEdition />} />
            <Route path="/newsletter" >
            <header className="sticky top-0 z-50 opacity-80">
                <Navigation navigation={alt_navigation}> </Navigation>
              </header>
              <Newsletter />
            </Route>
            <Route path="/imprint">
              <header className="sticky top-0 z-50 opacity-80">
                <Navigation navigation={alt_navigation}> </Navigation>
              </header>
              <Imprint />
            </Route>

            <Route path="/">
              <header className="sticky top-0 z-50 opacity-80">
                <Navigation navigation={default_navigation}> </Navigation>
              </header>
              <Home />
            </Route>
          </Switch>
          <footer>
            <Footer></Footer>
          </footer>
        </div>
      </div>
    </Router>
  );
}

function NewsletterEdition() {
  let { id } = useParams();

  var __html = `./${id}.htm`
      return(
        <>
        <header className="sticky top-0 z-50 opacity-80">
                <Navigation navigation={alt_navigation}> </Navigation>
              </header>
              <main>
              
        <Iframe url={__html}
        className="w-full h-screen min-h-screen min"
        display="initial"
        position="relative"/>
        </main>
        </>
      );

}

export default App;
