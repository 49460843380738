// import {
//   Link
// } from "react-router-dom";

// import AnchorLink from 'react-anchor-link-smooth-scroll'



const navigation = {
  company: [
    { name: 'hello@lunarventures.eu', href: 'mailto:hello@lunarventures.eu' },
  ],
  legal: [
    { name: 'Imprint', href: '/imprint', anchor: false },

  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/lunar_vc',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/lunar-ventures/',
      icon: (props) => (
        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z" /></svg>),
    },
    {
      name: 'Medium',
      href: 'https://medium.com/lunar-ventures',
      icon: (props) => (
        <svg fill="currentColor" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z" /></svg>
      ),
    }
  ]
}

export default function Footer() {
  return (
    <footer className="bg-pickled-bluewood-500 text-left" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Contact</h3>
                <ul className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
                <ul className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                  <li>
                    <a href="https://www.iubenda.com/privacy-policy/93387859" className="text-base text-gray-300 hover:text-white iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://www.iubenda.com/privacy-policy/93387859/cookie-policy" className="text-base text-gray-300 hover:text-white iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
                  </li>
                </ul>

              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">

              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">

              </div>
            </div>
          </div>
          <div className="mt-12 xl:mt-0">

          </div>
        </div>
        <div className="border-t border-gray-700 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            <p className="mt-2 text-base text-gray-300">
            {/* <a href="/newsletter">              Read our past newsletters
</a> */}
            </p>
          </div>
          <form id="newsletter" className="mt-4 sm:flex sm:max-w-md lg:mt-0" action="//berlininnovation.us15.list-manage1.com/subscribe/post?u=6a71d9290ead656c2c0790fc4&amp;id=3e01a9c859" method="post"  noValidate={true}>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="EMAIL"
              id="newsletter-email"
              autoComplete="email"
              required
              className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400 sm:max-w-xs"
              placeholder="Enter your email"
            />
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                className="w-full bg-pink-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2022 Berlin Innovation Ventures GmbH - All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  )
}



// const navigation = {
//   about: [
//   ],
//   investments: [
//   ],
//   company: [
//   ],
//   legal: [
//     { name: 'Imprint', href: '/imprint', anchor: false },
//   ],
//   social: [
//     {
//       name: 'Twitter',
//       href: 'https://twitter.com/lunar_vc',
//       icon: (props) => (
//         <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
//           <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
//         </svg>
//       ),
//     },
//     {
//       name: 'LinkedIn',
//       href: 'https://www.linkedin.com/company/lunar-ventures/',
//       icon: (props) => (
// <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"/></svg>      ),
//     },
//     {
//       name: 'Medium',
//       href: 'https://medium.com/lunar-ventures',
//       icon: (props) => (
//         <svg fill="currentColor" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z" /></svg>
//       ),
//     }
//   ],
// }


// export default function Footer() {
//   return (
//     <footer className="bg-pickled-bluewood-500" aria-labelledby="footer-heading">
//       <h2 id="footer-heading" className="sr-only">
//         Footer
//       </h2>
//       <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:py-2 lg:px-8">
//         <div className="xl:grid xl:grid-cols-1 xl:gap-8">
//           <div className="grid grid-cols-1 gap-8 xl:col-span-2">
//             <div className="md:grid grid-cols-1 md:gap-8">
//               <div className="mt-12">
//                 <h3 className="text-sm font-semibold text-pickled-bluewood-100 tracking-wider uppercase">Legal</h3>
//                 <ul className="mt-4 space-y-4">
//                   {navigation.legal.map((item) => (
//                     <>
//                       <li key={item.name}>
//                         {item.anchor ? <Link to={item.href} className="text-base text-gray-300 hover:text-white">
//                           {item.name}
//                         </Link> : <Link to={item.href} className="text-base text-gray-300 hover:text-white">
//                           {item.name}
//                         </Link>}
//                       </li>
//                       <li>
//                         <a href="https://www.iubenda.com/privacy-policy/93387859" className="text-base text-gray-300 hover:text-white iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
//                       </li>
//                       <li>
//                         <a href="https://www.iubenda.com/privacy-policy/93387859/cookie-policy" className="text-base text-gray-300 hover:text-white iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
//                       </li>

//                     </>
//                   ))}
//                 </ul>

//                 <h3 className="text-sm font-semibold mt-8 text-pickled-bluewood-100 tracking-wider"><a href="mailto:hello@lunarventures.eu">hello@lunarventures.eu</a></h3>
//                 <div className="mt-8 pt-10 w-1/3 mx-auto xl:mt-0">
//             <h3 className="text-sm font-semibold text-pickled-bluewood-100 tracking-wider uppercase">
//               Subscribe to our newsletter
//             </h3>
//             <p className="my-4 text-base text-gray-300">
//               The latest news, articles, and resources, from Lunar Ventures sent to your inbox weekly.
//             </p>
//             <form className="flex ">
//               <label htmlFor="email-address" className="sr-only">
//                 Email address
//               </label>
//               <input
//                 type="email"
//                 name="email-address"
//                 id="email-address"
//                 autoComplete="email"
//                 required
//                 className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
//                 placeholder="Enter your email"
//               />
//               <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
//                 <button
//                   type="submit"
//                   className="w-full bg-pink-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-pink-500"
//                 >
//                   Subscribe
//                 </button>
//               </div>
//             </form>
//           </div>
//               </div>
//             </div>
//           </div>

//         </div>
//         <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
//           <div className="flex space-x-6 md:order-2">
//             {navigation.social.map((item) => (
//               <a key={item.name} href={item.href} rel="noreferrer" target="_blank" className="text-pickled-bluewood-400 hover:text-gray-300">
//                 <span className="sr-only">{item.name}</span>
//                 <item.icon className="h-6 w-6" aria-hidden="true" />
//               </a>
//             ))}
//           </div>
//           <p className="mt-8 text-base text-pickled-bluewood-400 md:order-1">
//             &copy; 2020 Lunar Venures, All rights reserved.
//           </p>
//         </div>
//       </div>
//     </footer>
//   )
// }


