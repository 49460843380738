import { useState, useEffect } from 'react'
import Prismic from '@prismicio/client'
import dotenv from 'dotenv'
dotenv.config()

const apiEndpoint = 'https://lunarventures.prismic.io/api/v2'
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const Client = Prismic.client(apiEndpoint, { accessToken })

export default function Team() {

  const [team, setTeamData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'team'),
        { orderings: '[my.team.position]' }

      )
      if (response) {
        setTeamData(response.results)
      }
    }
    fetchData()
  }, [])

  return (
    <div className="bg-white text-left">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 ">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-normal text-pickled-bluewood-500 tracking-tight sm:text-4xl">Meet our team</h2>
            <p className="text-xl font-light tracking-wide text-gray-700">
              We are technical investors commited to the European DeepTech ecosystem.
            </p>
          </div>
          <ul className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-4 lg:gap-4">
            {team.map((person) => (
              <li key={person.data.full_name[0].text} className="py-10 px-6 bg-pickled-bluewood-500 text-center  xl:px-10">
                <div className="space-y-6 xl:space-y-10">
                  <img className="mx-auto h-40 w-40 rounded-full xl:w-48 xl:h-48 border-2" src={person.data.avatar.url} alt="" />
                  <div className="space-y-2">
                    <div className="font-medium text-lg leading-6 space-y-1">
                      <h3 className="text-white">{person.data.full_name[0].text}</h3>
                      <h2 className="text-pink-400">{person.data.role[0].text}</h2>
                    </div>

                    <ul className="flex justify-center space-x-5">
                      {person.data.twitter.url && (
                        <li>
                          <a href={person.data.twitter.url} target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-300">
                            <span className="sr-only">Twitter</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                      )
                      }
                      {person.data.linkedin.url && (
                        <li>
                          <a href={person.data.linkedin.url} className="text-gray-400 hover:text-gray-300">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                      )
                      }
                      {person.data.medium.url && (
                        <li>
                          <a href={person.data.medium.url} className="text-gray-400 hover:text-gray-300">
                            <span className="sr-only">Medium</span>
                            <svg fill="currentColor" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z" /></svg>

                          </a>
                        </li>
                      )
                      }
                      {person.data.website.url && (
                        <li>
                          <a href={person.data.website.url} className="text-gray-400 hover:text-gray-300">
                            <span className="sr-only">Website</span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                            </svg>
                          </a>
                        </li>
                      )
                      }
                    </ul>
                    <p className="inline-block text-white tracking-wider font-extralight">
                      {person.data.short_bio[0].text}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}