import React from 'react';
// import { RichText, Elements } from 'prismic-reactjs';

import { useState, useEffect } from 'react'
import Prismic from '@prismicio/client'
import dotenv from 'dotenv'
dotenv.config()

const apiEndpoint = 'https://lunarventures.prismic.io/api/v2'
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const Client = Prismic.client(apiEndpoint, { accessToken })


function Imprint(props) {

    const [content, setContent] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'imprint')
            )
            if (response) {
                setContent(response.results[0].data.imprint)
            }
        }
        fetchData()
    }, [])

    return (
        <div className="bg-white">
            <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-xl mx-auto py-16 sm:py-24">
                    <div className="text-left">
                        <h1 className="mb-8 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                            Imprint
                        </h1>
                        {content.map((item) => {
                            switch (item.type) {
                                case 'heading1':
                                    return (<h1 className="pt-4 pb-4 text-4xl text-pickled-bluewood-700">{item.text}</h1>)
                                case 'heading2':
                                    return (<h2 className="pt-4 pb-4  text-3xl text-pickled-bluewood-700">{item.text}</h2>)
                                case 'heading3':
                                    return (<h3 className="pt-4 pb-4  text-2xl text-pickled-bluewood-700">{item.text}</h3>)
                                case 'heading4':
                                    return (<h3 className="pt-4 pb-4 font-bold text-1xl text-pickled-bluewood-700">{item.text}</h3>)    
                                default:
                                    return (<p className="tracking-normal break-wordsfont-light pb-3">{item.text}</p>)
                            }
                        })}
                    </div>
                </div>
            </main>

        </div>
    );
}

export default Imprint;



