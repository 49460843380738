import { useState, useEffect } from 'react'
import Prismic from '@prismicio/client'
import dotenv from 'dotenv'
dotenv.config()

const apiEndpoint = 'https://lunarventures.prismic.io/api/v2'
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
const Client = Prismic.client(apiEndpoint, { accessToken })


export default function Example() {

  const [ventures, setVentureData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'venture'),
        { orderings: '[my.venture.position]' }
      )
      if (response) {
        console.log(response.results)
        setVentureData(response.results)
      }
    }
    fetchData()
  }, [])

  return (
    <div className="bg-pickled-bluewood-500 text-white pt-12">
      <div className="mx-auto max-w-md px-4 text-left sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-3xl font-light text-white tracking-tight sm:text-4xl text-left">Portfolio</h2>
        <div className="grid grid-cols-1 pt-10 pb-10 gap-0.5 md:grid-cols-2 lg:grid-cols-3 ">
          {ventures.map((venture) => {
            return (
              <div key={venture.data.name[0].text} className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <div>
                <a href={venture.data.website.url} target="_blank" rel="noreferrer" className="flex-nowrap xt-center text-pink-500 ">
                     

                  <img className="max-h-20 max-w-20 mx-auto mb-5 opacity-100" src={venture.data.logo.url} alt={venture.data.name[0].text} /> </a>
                  <a href={venture.data.website.url} target="_blank" rel="noreferrer" className="flex-nowrap xt-center text-pink-500 ">
<h3 className="text-xl font-medium text-gray-800 tracking-wider text-center">{venture.data.name[0].text}</h3>     </a>            
                  <p className="flex-nowrap text-center h-12 my-4 text-gray-800">{venture.data.short_description[0].text} <br />
                  </p>

                </div>
              </div>)

          })}

        </div>
      </div>
    </div>
  )
}

